export const VOUCHERS = [
  {
    plans: [
      'light_anual_installment',
      'light_anual'
    ],
    discount: 66.89,
    code: 'VCFOIESCOLHIDO'
  },
  {
    plans: [
      'light_anual_installment',
      'light_anual'
    ],
    discount: 66.89,
    code: 'VHSYSVIP'
  }
];
