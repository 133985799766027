import PropTypes from 'prop-types';
import React, {
  useEffect, createContext, useContext, useCallback,
  useState
} from 'react';

const ChatContext = createContext({});

const ChatProvider = ({ children }) => {
  const [chatUserData, setChatUserData] = useState(null);
  console.log(chatUserData)

  const initialize = () => {
    try {
      const BASE_URL = "https://cloudchat.cloudhumans.com";
      const script = document.createElement("script");
      script.src = `${BASE_URL}/packs/js/sdk.js`;
      script.defer = true;
      script.async = true;
      
      script.onload = () => {
        if (window.chatwootSDK) {
          window.chatwootSDK.run({
            websiteToken: process.env.CLOUDHUMANS_WEBSITE_TOKEN,
            baseUrl: BASE_URL
          });
  
          setTimeout(() => {
            if (window.$chatwoot) {
              window.$chatwoot.setPosition("left");
            }
          }, 500);
        }
      };
  
      document.body.appendChild(script);
    } catch (err) {
      console.error('Error @ tracker.initializeChatwoot', err);
    }
  };  

  const getSubscriptionName = (planName, cycle, providerId) => {
    if (['Trial', 'Freemium'].includes(planName)) return planName;

    const normalizedCycles = {
      MONTHLY: 'mensal',
      YEARLY: 'anual'
    };

    const normalizedPaymentType = !providerId?.includes('sub_') ? ' parcelado' : '';

    return `${planName} ${normalizedCycles[cycle]}${normalizedPaymentType}`;
  };

  const handleIdentifyUser = useCallback((data) => {
    try {
      const {
        user_email,
        user_name,
        organization_logo,
        ...rest
      } = data;

      if (window.$chatwoot) {
        window.$chatwoot.setUser(user_email, {
          name: user_name,
          avatar_url: organization_logo,
          ...rest
        });
      }

      setChatUserData(rest);
    } catch (error) {
      console.error('[CHATWOOT ERROR]', error);
    }
  }, [setChatUserData]);

  const handleUpdateUser = useCallback((data) => {
    try {
      if (window.$chatwoot) {
        window.$chatwoot.setCustomAttributes(data);
      }

      setChatUserData((prevData) => ({ ...prevData, ...data }));
    } catch (error) {
      console.error('[CHATWOOT ERROR]', error);
    }
  }, []);

  const handleOpenChat = useCallback((customMsg) => {
    if (window.$chatwoot) {
      window.$chatwoot.toggle();
      if (customMsg) {
        window.$chatwoot.sendMessage(customMsg);
      }
    }
  }, []);

  const handleSessionLogout = useCallback(() => {
    if (window.$chatwoot) {
      window.$chatwoot.reset();
    }
  }, []);

  useEffect(() => {
    initialize();
  }, []);

  return (
    <ChatContext.Provider value={{
      chatIdentify: handleIdentifyUser,
      openChat: handleOpenChat,
      finishChatSession: handleSessionLogout,
      getChatSubscriptionName: getSubscriptionName,
      chatUpdate: handleUpdateUser
    }}
    >
      {children}
    </ChatContext.Provider>
  );
};

function useChat() {
  const context = useContext(ChatContext);

  if (!context) {
    throw new Error('useChat must be used within an ChatProvider');
  }

  return context;
}

ChatProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { ChatProvider, useChat };
